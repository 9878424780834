import html2canvas from "html2canvas";
import slugify from "slugify";

const isMobile = () => {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    navigator.userAgent
  );
};

const copyOrShare = ({ title }) => {
  const table = document.getElementById("countResultTable");

  if (!table) {
    console.error("Table element not found.");
    return; // Exit the function if table is not found
  }

  html2canvas(table).then((canvas) => {
    const dataURL = canvas.toDataURL("image/png");
    const blob = dataURItoBlob(dataURL);
    const file = new File([blob], `${slugify(title)}.png`, {
      type: "image/png",
    });

    if (navigator.canShare && isMobile()) {
      const data = {
        files: [file],
      };

      navigator
        .share(data)
        .then(() => console.log("Data copied to clipboard"))
        .catch((err) => {
          console.error("Error sharing image:", err);
        });
    } else {
      const item = [new ClipboardItem({ "image/png": file })];

      navigator.clipboard
        .write(item)
        .then(() => {
          console.log("Data copied to clipboard");
        })
        .catch((err) => {
          console.error("Error copying to clipboard:", err);
        });
    }
  });
};

const dataURItoBlob = (dataURI) => {
  const byteString = atob(dataURI.split(",")[1]);
  const ab = new ArrayBuffer(byteString.length);
  const ia = new Uint8Array(ab);
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }
  return new Blob([ab], { type: "image/png" });
};

export { copyOrShare };
