// src/components/FormPage.js
import React from "react";
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableFooter,
  Box,
} from "@mui/material";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import ReplayIcon from "@mui/icons-material/Replay";
import { useAuth } from "../../hooks/AuthProvider";
import { copyOrShare } from "../../utils/copyOrShare";

const CountResultTable = ({ title, result, onBack }) => {
  const { profile } = useAuth();

  return (
    <Paper
      sx={{
        margin: "0",
        outline: "transparent none",
        border: "transparent none",
        boxShadow: "none",
        minWidth: "100%",
        marginLeft: "30px",
        transition: "left 0.2s linear",
      }}
    >
      <Box
        display="flex"
        flexDirection="row"
        alignItems="flex-end"
        justifyContent="flex-end"
        minWidth="100%"
      >
        <Button variant="outlined" size="small" onClick={onBack}>
          <ReplayIcon sx={{ marginRight: 1 }} />
          Nova contagem
        </Button>
      </Box>
      <TableContainer
        sx={{
          maxHeight: 440,
          maxWidth: 600,
          align: "center",
          borderRadius: "7px",
          borderWidth: "1px",
          borderColor: "rgba(0, 0, 0, 0.12)",
          marginY: "10px",
        }}
      >
        <Table
          id="countResultTable"
          sx={{
            borderSpacing: "3px",
            borderCollapse: "separate",
          }}
        >
          <TableHead>
            <TableRow>
              <TableCell
                align="center"
                colSpan={2} // Span 2 columns for "CAT {countParameters.date}"
                sx={{
                  fontSize: "1.5rem", // Increase font size for "CAT {countParameters.date}"
                  backgroundColor: "#ED2D6B",
                  borderRadius: "7px",
                  color: "white",
                }}
              >
                {title}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                sx={{
                  fontSize: "1.1rem", // Increase font size for "CAT {countParameters.date}"
                  // make a bit bold
                  // fontWeight: "bold",
                  backgroundColor: "#d4cdd1",
                  borderRadius: "7px",
                }}
              >
                Região
              </TableCell>
              <TableCell
                sx={{
                  fontSize: "1.1rem", // Increase font size for "CAT {countParameters.date}"
                  // make a bit bold
                  // fontWeight: "bold",
                  backgroundColor: "#d4cdd1",
                  borderRadius: "7px",
                  textAlign: "center",
                  width: "110px",
                }}
              >
                Qtd
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody
            sx={{
              borderSpacing: "0 10px",
            }}
          >
            {result.map((row, index, entries) => (
              <TableRow
                key={index}
                sx={{
                  "&:last-child td, &:last-child th": {
                    border: 0,
                  },
                }}
              >
                <TableCell>{row.concat_uf_municipio}</TableCell>
                <TableCell sx={{ textAlign: "center" }}>{row.dcount}</TableCell>
              </TableRow>
            ))}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TableCell
                sx={{
                  fontSize: "1.1rem", // Increase font size for "CAT {countParameters.date}"
                  // make a bit bold
                  // fontWeight: "bold",
                  backgroundColor: "#d4cdd1",
                  borderRadius: "7px",
                  fontWeight: "bold",
                  color: "black",
                }}
              >
                TOTAL
              </TableCell>
              <TableCell
                sx={{
                  fontSize: "1.1rem",
                  backgroundColor: "#d4cdd1",
                  borderRadius: "7px",
                  textAlign: "center",
                  fontWeight: "bold",
                  color: "black",
                }}
              >
                {result.reduce((accumulator, row) => {
                  return accumulator + row.dcount;
                }, 0)}
              </TableCell>
            </TableRow>
            <TableRow sx={{ border: "transparent none" }}>
              <TableCell
                align="center"
                colSpan={2}
                sx={{
                  fontSize: "0.85rem",
                  padding: "0 5px 0 0",
                  border: "transparent none",
                }}
              >
                realizada em {new Date().toLocaleString("pt-br")}, por{" "}
                <strong>{profile?.name}</strong>
              </TableCell>
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
      <Box
        display="flex"
        flexDirection="row"
        alignItems="flex-end"
        justifyContent="flex-end"
        minWidth="100%"
        backgroundColor="#fff"
        marginTop={4}
        sx={{
          position: {
            xs: "fixed",
            md: "relative",
          },
          bottom: {
            xs: "30px",
            md: "auto",
          },
          left: 0,
          padding: {
            xs: "20px 20px 0 20px",
            md: "0",
          },
        }}
      >
        <Button
          variant="contained"
          onClick={() => copyOrShare({ title })}
          fullWidth
        >
          <Typography sx={{ display: { xs: "none", md: "block" } }}>
            Copiar
          </Typography>
          <Typography sx={{ display: { xs: "block", md: "none" } }}>
            Compartilhar
          </Typography>
        </Button>
      </Box>
    </Paper>
  );
};

export default CountResultTable;
