// src/components/FormPage.js
import React, { useMemo, useState } from "react";
import { FormControl, Box } from "@mui/material";
import axios from "axios";
import ParamsGeo from "./ParamsGeo";
import ParamsDate from "./ParamsDate";
import Button from "@mui/material/Button";
// import Typography from "@mui/material/Typography";
import CountFormWrapper from "./CountFormWrapper";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import Typography from "@mui/material/Typography";
import CircularProgress, {
  circularProgressClasses,
} from "@mui/material/CircularProgress";

const FormCat = () => {
  const [countParameters, setCountParameters] = useState({
    geo: "",
    date: "",
    houve_afastamento: false,
    houve_obito: false,
  });

  const [isCounting, setIsCounting] = useState(false);

  const [countResult, setCountResult] = useState(null);

  const canSubmit = useMemo(() => {
    return !isCounting && countParameters.geo && countParameters.geo.length;
  }, [countParameters, isCounting]);

  const countResultTableTitle = useMemo(() => {
    if (countResult && Array.isArray(countResult)) {
      return `CAT -
        ${
          countParameters.date.endDate
            ? `${countParameters.date.startDate.replace(
                /([0-9]{4})([0-9]{2})/,
                "$2/$1"
              )} à ${countParameters.date.endDate.replace(
                /([0-9]{4})([0-9]{2})/,
                "$2/$1"
              )}`
            : countParameters.date.startDate.replace(
                /([0-9]{4})([0-9]{2})/,
                "$2/$1"
              )
        }`;
    } else {
      return null;
    }
  }, [countResult, countParameters]);

  const handleSubmit = (e) => {
    setIsCounting(true);
    e.preventDefault();

    axios
      .post(
        process.env.REACT_APP_SERVICE_URL + "/counts/retrieve_cat",
        countParameters
      )
      .then((response) => {
        setCountResult(response.data);
        console.log(response.data);
      })
      .catch((error) => {
        console.error("Error submitting form:", error);
      });
  };

  const handleGeoChange = (geo) => {
    setCountParameters({ ...countParameters, geo });
  };

  const handleDateChange = (date) => {
    setCountParameters({ ...countParameters, date });
  };

  const handleHouveAfastamentoChange = (e, houve_afastamento) => {
    setCountParameters({
      ...countParameters,
      houve_afastamento: houve_afastamento === "sim" ? true : false,
    });
  };

  const handleHouveObitoChange = (e, houve_obito) => {
    setCountParameters({
      ...countParameters,
      houve_obito: houve_obito === "sim" ? true : false,
    });
  };

  return (
    <CountFormWrapper
      title="CAT"
      countResultTableTitle={countResultTableTitle}
      countResult={countResult}
      onBack={() => {
        setCountResult(null);
        setIsCounting(false);
      }}
      form={
        <form onSubmit={handleSubmit}>
          <FormControl fullWidth>
            <ParamsGeo onChange={handleGeoChange} />
            <Box marginTop="35px" marginBottom="35px">
              <ParamsDate onChange={handleDateChange} />
            </Box>
            <Box marginTop="35px" marginBottom="10px">
              <Typography
                variant="h6"
                gutterBottom={true}
                display="flex"
                flexDirection="row"
                alignItems="center"
                justifyContent="space-between"
              >
                Filtros Adicionais{" "}
                <ToggleButtonGroup
                  color="primary"
                  value={countParameters.houve_afastamento ? "sim" : null}
                  size="small"
                  exclusive
                  onChange={handleHouveAfastamentoChange}
                  aria-label="Incluir filiais"
                >
                  <ToggleButton
                    defaultChecked
                    value="sim"
                    sx={{
                      "&.Mui-selected": {
                        backgroundColor: "#ED2D6B",
                        color: "white",
                      },
                    }}
                  >
                    {countParameters.houve_afastamento ? (
                      <CheckBoxIcon sx={{ marginRight: "5px" }} />
                    ) : (
                      <CheckBoxOutlineBlankIcon sx={{ marginRight: "5px" }} />
                    )}{" "}
                    Houve Afastamento
                  </ToggleButton>
                </ToggleButtonGroup>
              </Typography>
            </Box>
            <Box marginTop="10px" marginBottom="35px">
              <Typography
                variant="h6"
                gutterBottom={true}
                display="flex"
                flexDirection="row"
                alignItems="center"
                justifyContent="space-between"
              >
                &nbsp;{" "}
                <ToggleButtonGroup
                  color="primary"
                  value={countParameters.houve_obito ? "sim" : null}
                  size="small"
                  // always left of box

                  exclusive
                  onChange={handleHouveObitoChange}
                  aria-label="Incluir filiais"
                >
                  <ToggleButton
                    defaultChecked
                    value="sim"
                    sx={{
                      "&.Mui-selected": {
                        backgroundColor: "#ED2D6B",
                        color: "white",
                      },
                    }}
                  >
                    {countParameters.houve_obito ? (
                      <CheckBoxIcon sx={{ marginRight: "5px" }} />
                    ) : (
                      <CheckBoxOutlineBlankIcon sx={{ marginRight: "5px" }} />
                    )}{" "}
                    Houve Óbito
                  </ToggleButton>
                </ToggleButtonGroup>
              </Typography>
            </Box>
            <Button variant="contained" type="submit" disabled={!canSubmit}>
              {isCounting ? (
                <>
                  Aguarde, em andamento.. &nbsp;
                  <CircularProgress
                    sx={{
                      color: "primary",
                      animationDuration: "2550ms",
                      [`& .${circularProgressClasses.circle}`]: {
                        strokeLinecap: "round",
                      },
                    }}
                    size={20}
                    thickness={4}
                  />
                </>
              ) : (
                "Gerar Contagem"
              )}
            </Button>
          </FormControl>
        </form>
      }
    ></CountFormWrapper>
  );
};

export default FormCat;
