// src/Routes.js
import React, { useState } from "react";
import Home from "./components/Home";
import FormCat from "./components/FormCat";
import FormIndef from "./components/FormIndef";
import FormBpc from "./components/FormBpc";
import FormApos from "./components/FormApos";
import FormDpvat from "./components/FormDpvat";
import FormDem from "./components/FormDem";
import FormPasep from "./components/FormPasep";
import FormBen from "./components/FormBen";
import Typography from "@mui/material/Typography";
import Toolbar from "@mui/material/Toolbar";
import AppBar from "@mui/material/AppBar";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Divider from "@mui/material/Divider";
import Container from "@mui/material/Container";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Link,
  useLocation,
} from "react-router-dom";
// import HomeMiniIcon from "@mui/icons-material/HomeMini";
import Box from "@mui/material/Box";
import logoHorizontal from "./cp-logo-horizontal.svg";
import logoIcone from "./cp-logo-icone.svg";
import Drawer from "@mui/material/Drawer";
import { List, ListItem, ListItemButton } from "@mui/material";
import HomeRoundedIcon from "@mui/icons-material/Home";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import Login from "./components/Login";
import { ProtectedRoute } from "./ProtectedRoute";
import { AuthProvider } from "./hooks/AuthProvider";
import ProfileMenu from "./components/ProfileMenu";

const pages = [
  "CAT",
  "Indeferidos",
  "BPC",
  "Aposentadoria",
  "DPVAT",
  "Demitidos",
  "PASEP",
  "Beneficiarios",
];

const openInNewTab = (url) => {
  window.open(url, "_blank", "noreferrer");
};

const Rotas = () => {
  const [menuState, setMenuState] = useState(false);
  const location = useLocation;
  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setMenuState(open);
  };

  return (
    <Router>
      <AuthProvider>
        <AppBar position="static">
          <Container maxWidth="xl">
            <Toolbar disableGutters>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={toggleDrawer(true)}
                color="inherit"
              >
                <MenuIcon />
              </IconButton>
              <Link
                to="/"
                style={{
                  textDecoration: "none",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  flexDirection: "row",
                  color: "#fff",
                }}
              >
                <Box sx={{ display: { xs: "none", md: "block" } }}>
                  <img src={logoHorizontal} alt="logo" width="180" />
                </Box>
                <Box sx={{ display: { xs: "block", md: "none" } }}>
                  <img src={logoIcone} alt="logo" width="40" />
                </Box>
                <Typography
                  variant="h6"
                  noWrap
                  sx={{
                    mr: 2,
                    ml: 2,
                    fontFamily: "monospace",
                    fontWeight: 700,
                    letterSpacing: ".3rem",
                    color: "inherit",
                    textDecoration: "none",
                  }}
                >
                  CONTAGENS
                </Typography>
              </Link>
              <ProfileMenu />
            </Toolbar>
            <Drawer
              anchor="left"
              open={menuState}
              onClose={toggleDrawer(false)}
            >
              <List
                disablePadding
                sx={{
                  width: 210,
                }}
              >
                <ListItem>
                  <Typography
                    sx={{
                      ml: 2,
                      fontFamily: "monospace",
                      fontWeight: 700,
                      letterSpacing: ".3rem",
                      color: "white",
                      textDecoration: "none",
                      fontSize: 20,
                    }}
                  >
                    CONTAGENS
                  </Typography>
                </ListItem>
                <Divider />
                <ListItem
                  component={Link}
                  to="/"
                  color="inherit"
                  onClick={toggleDrawer(false)}
                  disablePadding
                  style={{
                    textDecoration: "none",
                    color: "#fff",
                  }}
                >
                  <ListItemButton
                    sx={{
                      "&:hover": {
                        backgroundColor: "rgba(0, 0, 0, 0.03)",
                        textDecoration: "none",
                        borderRadius: "5px",
                        // marginLeft: "25px",
                      },
                      // marginLeft: "25px",
                    }}
                  >
                    <HomeRoundedIcon
                      fontSize="small"
                      color="inherit"
                      style={{
                        textDecoration: "none",
                        display: "flex",
                        alignItems: "left",
                        justifyContent: "left",
                        flexDirection: "row",
                        color: "#fff",
                        marginBottom: "3px",
                      }}
                    >
                      {" "}
                    </HomeRoundedIcon>
                    <Typography
                      marginLeft={"15px"}
                      textAlign="center"
                      color="white"
                      fontWeight="bold"
                      fontSize={17}
                    >
                      Inicio
                    </Typography>
                  </ListItemButton>
                </ListItem>

                <Divider />

                <Box
                  backgroundColor={"#e03169"}
                  width="100%"
                  sx={{ boxShadow: 1 }}
                >
                  <ListItem sx={{ py: 2, px: 3 }}>
                    <Typography
                      textAlign="left"
                      color="white"
                      fontSize={17}
                      sx={{ fontWeight: 600, letterSpacing: ".03rem" }}
                    >
                      Mailings
                    </Typography>
                  </ListItem>
                  <Divider />
                  {pages.map((page) => (
                    <ListItem
                      key={page}
                      component={Link}
                      to={`/counts/${page.toLowerCase()}`}
                      onClick={toggleDrawer(false)}
                      disablePadding
                      sx={{
                        "&:hover": {
                          backgroundColor: "rgba(0, 0, 0, 0.03)",
                          textDecoration: "none",
                          borderRadius: "5px",
                          // marginLeft: "25px",
                        },
                        // marginLeft: "25px",
                      }}
                    >
                      <ListItemButton sx={{ color: "white" }}>
                        <Typography
                          sx={{
                            marginLeft: "25px",
                            // marginRight: "15px",
                            fontWeight: 600,
                            fontSize: 16,
                          }}
                        >
                          {page}
                        </Typography>
                      </ListItemButton>
                    </ListItem>
                  ))}
                </Box>
                <Divider sx={{ height: 3 }} />
                <Box
                  backgroundColor={"#e03169"}
                  width="100%"
                  sx={{ boxShadow: 1 }}
                >
                  <ListItem sx={{ py: 2, px: 3 }}>
                    <Typography
                      textAlign="left"
                      color="white"
                      fontSize={17}
                      sx={{ fontWeight: 600, letterSpacing: ".03rem" }}
                    >
                      Links úteis
                    </Typography>
                  </ListItem>
                  <Divider />
                  <ListItem
                    component={Link}
                    onClick={() =>
                      openInNewTab(
                        "https://cbo.mte.gov.br/cbosite/pages/home.jsf"
                      )
                    }
                    disablePadding
                    sx={{
                      "&:hover": {
                        backgroundColor: "rgba(0, 0, 0, 0.03)",
                        textDecoration: "none",
                        borderRadius: "5px",
                      },
                    }}
                  >
                    <ListItemButton sx={{ color: "white" }}>
                      <Typography
                        sx={{
                          marginLeft: "25px",
                          fontWeight: 600,
                          fontSize: 16,
                        }}
                      >
                        CBO
                      </Typography>
                    </ListItemButton>
                  </ListItem>
                  <ListItem
                    component={Link}
                    onClick={() =>
                      openInNewTab(
                        "https://www.normaslegais.com.br/guia/clientes/codigo-beneficios-previdencia-social.htm"
                      )
                    }
                    disablePadding
                    sx={{
                      "&:hover": {
                        backgroundColor: "rgba(0, 0, 0, 0.03)",
                        textDecoration: "none",
                        borderRadius: "5px",
                      },
                    }}
                  >
                    <ListItemButton sx={{ color: "white" }}>
                      <Typography
                        sx={{
                          marginLeft: "25px",
                          fontWeight: 600,
                          fontSize: 16,
                        }}
                      >
                        Espécies
                      </Typography>
                    </ListItemButton>
                  </ListItem>
                  <ListItem
                    component={Link}
                    onClick={() =>
                      openInNewTab(
                        "https://concla.ibge.gov.br/busca-online-cnae.html"
                      )
                    }
                    disablePadding
                    sx={{
                      "&:hover": {
                        backgroundColor: "rgba(0, 0, 0, 0.03)",
                        textDecoration: "none",
                        borderRadius: "5px",
                      },
                    }}
                  >
                    <ListItemButton sx={{ color: "white" }}>
                      <Typography
                        sx={{
                          marginLeft: "25px",
                          fontWeight: 600,
                          fontSize: 16,
                        }}
                      >
                        CNAES
                      </Typography>
                    </ListItemButton>
                  </ListItem>
                </Box>
              </List>
            </Drawer>
          </Container>
        </AppBar>
        <TransitionGroup>
          <CSSTransition
            key={location.pathname}
            sx={{
              "&.fade-enter": {
                opacity: 0,
              },
              "&.fade-enter-active": {
                opacity: 1,
                transition: "opacity 600ms ease-in",
              },
            }}
            timeout={600}
          >
            <Routes>
              <Route
                exact
                path="/"
                element={
                  <ProtectedRoute>
                    <Inicio />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/counts/cat"
                element={
                  <ProtectedRoute>
                    <Cat />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/counts/indeferidos"
                element={
                  <ProtectedRoute>
                    <Indef />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/counts/bpc"
                element={
                  <ProtectedRoute>
                    <Bpc />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/counts/aposentadoria"
                element={
                  <ProtectedRoute>
                    <Apos />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/counts/dpvat"
                element={
                  <ProtectedRoute>
                    <Dpvat />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/counts/demitidos"
                element={
                  <ProtectedRoute>
                    <Dem />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/counts/pasep"
                element={
                  <ProtectedRoute>
                    <Pasep />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/counts/beneficiarios"
                element={
                  <ProtectedRoute>
                    <Ben />
                  </ProtectedRoute>
                }
              />
              <Route path="/login" element={<LoginScreen />} />
            </Routes>
          </CSSTransition>
        </TransitionGroup>
      </AuthProvider>
    </Router>
  );
};

function Inicio() {
  return <Home />;
}

function Cat() {
  return <FormCat />;
}

function Indef() {
  return <FormIndef />;
}

function Bpc() {
  return <FormBpc />;
}

function Apos() {
  return <FormApos />;
}

function Dpvat() {
  return <FormDpvat />;
}

function Dem() {
  return <FormDem />;
}

function Pasep() {
  return <FormPasep />;
}

function Ben() {
  return <FormBen />;
}

function LoginScreen() {
  return <Login />;
}

export default Rotas;
