// src/components/FormPage.js
import React, { useMemo, useState } from "react";
import { Box, FormControl } from "@mui/material";
import axios from "axios";
import ParamsGeo from "./ParamsGeo";
import Button from "@mui/material/Button";
import CountFormWrapper from "./CountFormWrapper";
import Typography from "@mui/material/Typography";
import CircularProgress, {
  circularProgressClasses,
} from "@mui/material/CircularProgress";
import SitFIeld from "./SitPasepFIeld";

const FormPasep = () => {
  const [countParameters, setCountParameters] = useState({
    geo: "",
    sit: "",
  });

  const [isCounting, setIsCounting] = useState(false);

  const [countResult, setCountResult] = useState(null);

  const canSubmit = useMemo(() => {
    return !isCounting && countParameters.geo && countParameters.geo.length;
  }, [countParameters, isCounting]);

  const handleSubmit = (e) => {
    setIsCounting(true);
    e.preventDefault();

    axios
      .post(
        process.env.REACT_APP_SERVICE_URL + "/counts/retrieve_pasep",
        countParameters
      )
      .then((response) => {
        setCountResult(response.data);
      })
      .catch((error) => {
        console.error("Error submitting form:", error);
      });
  };

  const handleGeoChange = (geo) => {
    setCountParameters({ ...countParameters, geo });
  };

  const handleSituacaoChange = (sit) => {
    setCountParameters({
      ...countParameters,
      sit,
    });
  };

  return (
    <CountFormWrapper
      title="TENTATIVA PASEP"
      countResultTableTitle="TENTATIVA PASEP"
      countResult={countResult}
      onBack={() => {
        setCountResult(null);
        setIsCounting(false);
      }}
      form={
        <form onSubmit={handleSubmit}>
          <FormControl fullWidth>
            <ParamsGeo onChange={handleGeoChange} />
            <Typography
              variant="h6"
              gutterBottom={true}
              display="flex"
              flexDirection="row"
              alignItems="center"
              justifyContent="space-between"
              marginTop="35px"
            >
              Situação{" "}
            </Typography>
            <Box marginTop="10px" marginBottom="35px">
              <SitFIeld onChange={handleSituacaoChange} />
            </Box>
            <Button variant="contained" type="submit" disabled={!canSubmit}>
              {isCounting ? (
                <>
                  Aguarde, em andamento.. &nbsp;
                  <CircularProgress
                    sx={{
                      color: "primary",
                      animationDuration: "2550ms",
                      [`& .${circularProgressClasses.circle}`]: {
                        strokeLinecap: "round",
                      },
                    }}
                    size={20}
                    thickness={4}
                  />
                </>
              ) : (
                "Gerar Contagem"
              )}
            </Button>
          </FormControl>
        </form>
      }
    ></CountFormWrapper>
  );
};

export default FormPasep;
