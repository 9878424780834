import React, {
  useEffect,
  useState,
  useImperativeHandle,
  forwardRef,
} from "react";
import axios from "axios";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { Box } from "@mui/material";

const UfField = forwardRef(({ onChange, disabled, exclude }, ref) => {
  const [ufAll, setUfAll] = useState([]);
  const [uf, setUf] = useState(null);

  useEffect(() => {
    axios
      .get(process.env.REACT_APP_SERVICE_URL + "/counts/estados")
      .then((response) => {
        setUfAll(response.data);
      })
      .catch((error) => {
        console.error("Erro pegando estados:", error);
      });
  }, []);

  const clearState = () => {
    setUf(null);
  };

  const setState = (uf) => {
    const editUf = uf;
    const selectedUf = ufAll.find((uf) => uf.codigo_uf === editUf.id);

    setUf(selectedUf.uf);
    onChange(selectedUf);
    return selectedUf;
    // console.log(selectedUf);
  };

  useImperativeHandle(ref, () => ({
    clearState,
    setState, // Added setUf to the exposed methods
  }));

  const handleChange = (e, value) => {
    if (value) {
      const selectedUF = ufAll.find((uf) => uf.uf === value.value);
      setUf(selectedUF.uf);
      onChange(selectedUF);
      // console.log(selectedUF);
    }
  };

  return (
    <Box marginBottom="10px" className="state-field" width="100%">
      <Autocomplete
        disabled={disabled}
        disablePortal
        id="combo-box-uf"
        getOptionDisabled={(option) => exclude && exclude.includes(option.id)}
        options={ufAll.map((uf) => ({
          label: uf.uf + " - " + uf.nome,
          value: uf.uf,
          id: uf.codigo_uf,
        }))}
        sx={{ width: "100%" }}
        value={uf}
        onChange={handleChange}
        renderInput={(params) => <TextField {...params} label="UF" />}
        isOptionEqualToValue={(option, value) => option.value === value}
      />
    </Box>
  );
});

export default UfField;
