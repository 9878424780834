import React, { useState } from "react";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";

const ConsignadoField = ({ onChange }) => {
  const [checked, setChecked] = useState(false);

  const handleChange = (e) => {
    const newValue = e.target.checked;
    setChecked(newValue);
    onChange(newValue);
  };

  return (
    <FormControlLabel
      label="Sim"
      control={
        <Checkbox
          checked={checked}
          onChange={handleChange}
          defaultValue={false}
        />
      }
    />
  );
};

export default ConsignadoField;
