import React, { useEffect, useState } from "react";
import axios from "axios";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { Checkbox, createFilterOptions, ListItem } from "@mui/material";
import uniqBy from "lodash/uniqBy";
import match from "autosuggest-highlight/match";
import parse from "autosuggest-highlight/parse";

const CboField = ({ onChange }) => {
  let filteredCboOptions = [];

  const [cboAll, setCboAll] = useState([]);
  const [cbo, setCbo] = useState([]);
  const [cboSearchInputValue, setCboSearchInputValue] = useState("");

  useEffect(() => {
    axios
      .get(process.env.REACT_APP_SERVICE_URL + "/counts/cbo")
      .then((response) => {
        setCboAll(response.data);
      })
      .catch((error) => {
        console.error("Erro pegando cbo:", error);
      });
  }, []);

  const handleChange = (e, values, reason) => {
    let newCbo = [];
    if (reason === "clear") {
      setCbo([]);
      onChange([]);
      return;
    } else {
      if (values.find((option) => option.value === "all")) {
        setCboSearchInputValue("");
        newCbo = [...cbo, ...filteredCboOptions];
      } else {
        newCbo = [...values];
      }
    }
    setCbo(newCbo);
    onChange(newCbo);
    filteredCboOptions = [];
  };

  return (
    <div className="state-field">
      <Autocomplete
        multiple
        id="cbo"
        options={cboAll.map((cbo) => ({
          label: cbo.codigo + " - " + cbo.ocupacao,
          value: cbo.codigo,
        }))}
        value={cbo}
        onChange={handleChange}
        inputValue={cboSearchInputValue}
        onInputChange={(_, value, reason) =>
          reason !== "reset" && setCboSearchInputValue(value)
        }
        getOptionLabel={(option) => option.label}
        filterOptions={(options, params) => {
          const filter = createFilterOptions({
            matchFrom: "start",
            stringify: (option) => {
              return option.label;
            },
            limit: 100,
          });

          const filtered = uniqBy(filter(options, params), "value");
          filteredCboOptions = filtered;

          if (params.inputValue.length > 0) {
            return [
              { label: "SELECIONAR TODOS...", value: "all" },
              ...filtered,
            ];
          } else {
            return filtered;
          }
        }}
        renderInput={(params) => (
          <TextField {...params} label="CBO" placeholder="CBO" />
        )}
        sx={{ width: "100%" }}
        isOptionEqualToValue={(option, value) => option.value === value.value}
        renderOption={(props, option, { selected, inputValue }) => {
          const matches = match(option.label, inputValue);
          const parts = parse(option.label, matches);
          return (
            <ListItem
              {...props}
              sx={{
                backgroundColor:
                  option.value === "all" ? "#e6e5e4" : "transparent",
                "&:hover": {
                  backgroundColor: "#e6e5e4 !important",
                },
                fontSize: "0.85rem",
              }}
            >
              <Checkbox checked={selected} sx={{ ml: 2 * option.depth }} />
              <div>
                {parts.map((part, index) => (
                  <span
                    key={index}
                    style={{
                      fontWeight:
                        option.value === "all"
                          ? "700"
                          : part.highlight
                          ? 700
                          : 400,
                    }}
                  >
                    {part.text}
                  </span>
                ))}
              </div>
            </ListItem>
          );
        }}
      />
    </div>
  );
};

export default CboField;
