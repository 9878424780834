import {
  Avatar,
  Box,
  ListItemIcon,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import { useAuth } from "../../hooks/AuthProvider";
import Logout from "@mui/icons-material/Logout";
import { useState } from "react";

const ProfileMenu = () => {
  const { profile, logout } = useAuth();

  const [anchorEl, setAnchorEl] = useState();

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <>
      {profile && (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "row",
            borderRadius: "14px",
            // backgroundColor: "#fff",
            backgroundColor: "rgba(0, 0, 0, 0.08)",
            flexGrow: 0,
            marginLeft: "auto",
            cursor: "pointer",
          }}
        >
          <Box
            onClick={handleClick}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "row",
            }}
          >
            <Avatar
              src={profile?.picture}
              sx={{
                width: 32,
                height: 32,
                border: "3px solid #fff",
                borderRadius: "50%",
              }}
            />

            <Typography
              color="#000"
              sx={{
                display: { xs: "none", md: "block" },
                marginLeft: 1,
                marginRight: 2,
                color: "#fff",
                fontWeight: 600,
              }}
            >
              {profile?.name}
            </Typography>
          </Box>
          <Menu
            anchorEl={anchorEl}
            id="account-menu"
            open={open}
            onClose={handleClose}
            onClick={handleClose}
            PaperProps={{
              elevation: 0,
              sx: {
                overflow: "visible",
                filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                mt: 1.5,
                "& .MuiAvatar-root": {
                  width: 32,
                  height: 32,
                  ml: -0.5,
                  mr: 1,
                },
                "&:before": {
                  content: '""',
                  display: "block",
                  position: "absolute",
                  top: 0,
                  right: 14,
                  width: 10,
                  height: 10,
                  bgcolor: "background.paper",
                  transform: "translateY(-50%) rotate(45deg)",
                  zIndex: 0,
                },
              },
            }}
            transformOrigin={{ horizontal: "right", vertical: "top" }}
            anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
          >
            <MenuItem
              onClick={() => {
                handleClose();
                logout();
              }}
            >
              <ListItemIcon>
                <Logout fontSize="small" />
              </ListItemIcon>
              Logout
            </MenuItem>
          </Menu>
        </Box>
      )}
    </>
  );
};

export default ProfileMenu;
