import React from "react";
import { Grid, Typography, IconButton, Card, CardContent } from "@mui/material";
// icons
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import CarCrash from "@mui/icons-material/CarCrash";
import ElderlyIcon from "@mui/icons-material/Elderly";
import WarningIcon from "@mui/icons-material/Warning";
import ArrowCircleRightRoundedIcon from "@mui/icons-material/ArrowCircleRightRounded";
import DoNotDisturbOnIcon from "@mui/icons-material/DoNotDisturbOn";
import LocalAtmIcon from "@mui/icons-material/LocalAtm";
import PersonOffIcon from "@mui/icons-material/PersonOff";
import HourglassBottomIcon from "@mui/icons-material/HourglassBottom";
import Title from "./Title";
import Paragraph from "./Paragraph";
import { Link } from "react-router-dom";

const Counts = () => {
  return (
    <Grid
      container
      spacing={0}
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        py: 10,
        px: 2,
      }}
    >
      <Grid item xs={12} sm={12} md={5} component="section">
        <Title text={"CONTAGENS DISPONÍVEIS"} textAlign={"start"} />

        <Typography
          variant="h6"
          component="h4"
          sx={{
            fontWeight: "400",
            paddingTop: 1,
          }}
        >
          Filtros diferem entre os Mailings, de acordo com a disponibilidade.
        </Typography>

        <Paragraph
          text={
            " Caso a demanda de sua contagem não esteja contemplada aqui,\
                    entre em contato com a Equipe de Tecnologia\
                    ."
          }
          maxWidth={"75%"}
          mx={0}
          textAlign={"start"}
        />
      </Grid>

      <Grid item xs={12} sm={6} md={3}>
        <Card
          square={true}
          sx={{
            minHeight: 200,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
            border: "1px solid #ccc",
          }}
        >
          <CardContent
            component={Link}
            to="/counts/indeferidos"
            sx={{
              "&:hover": {
                textDecoration: "none",
                borderRadius: "5px",
                // marginLeft: "25px",
              },
            }}
          >
            <IconButton>
              <DoNotDisturbOnIcon fontSize="large" color="secondary" />
            </IconButton>
            <Typography
              variant="h5"
              component="p"
              color={"black"}
              sx={{
                fontWeight: 700,
                textTransform: "capitalize",
              }}
            >
              Indeferidos
            </Typography>
          </CardContent>
        </Card>
      </Grid>

      <Grid item xs={12} sm={6} md={3}>
        <Card
          square={true}
          sx={{
            minHeight: 200,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
            border: "1px solid #ccc",
          }}
        >
          <CardContent
            component={Link}
            to="/counts/cat"
            sx={{
              "&:hover": {
                textDecoration: "none",
                borderRadius: "5px",
                // marginLeft: "25px",
              },
            }}
          >
            <IconButton>
              <WarningIcon fontSize="large" color="secondary" />
            </IconButton>
            <Typography
              variant="h5"
              component="p"
              color={"black"}
              sx={{
                fontWeight: 700,
                textTransform: "capitalize",
              }}
            >
              CAT
            </Typography>
          </CardContent>
        </Card>
      </Grid>

      <Grid
        item
        xs={12}
        sm={6}
        md={2}
        sx={{
          display: { xs: "none", sm: "block" },
        }}
      >
        <Card
          square={true}
          sx={{
            boxShadow: "none",
            minHeight: 180,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
          }}
        >
          <CardContent>
            <ArrowCircleRightRoundedIcon fontSize="large" color="secondary" />
          </CardContent>
        </Card>
      </Grid>

      <Grid item xs={12} sm={6} md={3}>
        <Card
          square={true}
          sx={{
            minHeight: 200,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
            border: "1px solid #ccc",
          }}
        >
          <CardContent
            component={Link}
            to="/counts/bpc"
            sx={{
              "&:hover": {
                textDecoration: "none",
                borderRadius: "5px",
                // marginLeft: "25px",
              },
            }}
          >
            <IconButton>
              <ElderlyIcon fontSize="large" color="secondary" />
            </IconButton>
            <Typography
              variant="h5"
              component="p"
              color={"black"}
              sx={{
                fontWeight: 700,
                textTransform: "capitalize",
              }}
            >
              Tentativa BPC
            </Typography>
          </CardContent>
        </Card>
      </Grid>

      <Grid item xs={12} sm={6} md={3}>
        <Card
          square={true}
          sx={{
            minHeight: 200,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
            border: "1px solid #ccc",
          }}
        >
          <CardContent
            component={Link}
            to="/counts/aposentadoria"
            sx={{
              "&:hover": {
                textDecoration: "none",
                borderRadius: "5px",
                // marginLeft: "25px",
              },
            }}
          >
            <IconButton>
              <HourglassBottomIcon fontSize="large" color="secondary" />
            </IconButton>
            <Typography
              variant="h5"
              component="p"
              color={"black"}
              sx={{
                fontWeight: 700,
                textTransform: "capitalize",
              }}
            >
              Aposentadoria
            </Typography>
          </CardContent>
        </Card>
      </Grid>

      <Grid item xs={12} sm={6} md={3}>
        <Card
          square={true}
          sx={{
            minHeight: 200,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
            border: "1px solid #ccc",
          }}
        >
          <CardContent
            component={Link}
            to="/counts/dpvat"
            sx={{
              "&:hover": {
                textDecoration: "none",
                borderRadius: "5px",
                // marginLeft: "25px",
              },
            }}
          >
            <IconButton>
              <CarCrash fontSize="large" color="secondary" />
            </IconButton>
            <Typography
              variant="h5"
              component="p"
              color={"black"}
              sx={{
                fontWeight: 700,
                textTransform: "capitalize",
              }}
            >
              DPVAT
            </Typography>
          </CardContent>
        </Card>
      </Grid>
      <Grid
        item
        xs={12}
        sm={6}
        md={2}
        sx={{
          display: { xs: "none", sm: "block" },
        }}
      >
        <Card
          square={true}
          sx={{
            boxShadow: "none",
            minHeight: 180,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
          }}
        ></Card>
      </Grid>
      <Grid item xs={12} sm={6} md={3}>
        <Card
          square={true}
          sx={{
            minHeight: 200,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
            border: "1px solid #ccc",
          }}
        >
          <CardContent
            component={Link}
            to="/counts/demitidos"
            sx={{
              "&:hover": {
                textDecoration: "none",
                borderRadius: "5px",
                // marginLeft: "25px",
              },
            }}
          >
            <IconButton>
              <PersonOffIcon fontSize="large" color="secondary" />
            </IconButton>
            <Typography
              variant="h5"
              component="p"
              color={"black"}
              sx={{
                fontWeight: 700,
                textTransform: "capitalize",
              }}
            >
              Demitidos
            </Typography>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12} sm={6} md={3}>
        <Card
          square={true}
          sx={{
            minHeight: 200,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
            border: "1px solid #ccc",
          }}
        >
          <CardContent
            component={Link}
            to="/counts/pasep"
            sx={{
              "&:hover": {
                textDecoration: "none",
                borderRadius: "5px",
                // marginLeft: "25px",
              },
            }}
          >
            <IconButton>
              <LocalAtmIcon fontSize="large" color="secondary" />
            </IconButton>
            <Typography
              variant="h5"
              component="p"
              color={"black"}
              sx={{
                fontWeight: 700,
                textTransform: "capitalize",
              }}
            >
              PASEP
            </Typography>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12} sm={6} md={3}>
        <Card
          square={true}
          sx={{
            minHeight: 200,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
            border: "1px solid #ccc",
          }}
        >
          <CardContent
            component={Link}
            to="/counts/beneficiarios"
            sx={{
              "&:hover": {
                textDecoration: "none",
                borderRadius: "5px",
                // marginLeft: "25px",
              },
            }}
          >
            <IconButton>
              <AccountBalanceIcon fontSize="large" color="secondary" />
            </IconButton>
            <Typography
              variant="h5"
              component="p"
              color={"black"}
              sx={{
                fontWeight: 700,
                textTransform: "capitalize",
              }}
            >
              Beneficiários
            </Typography>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

export default Counts;
