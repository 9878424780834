import React, { useEffect, useState } from "react";
import axios from "axios";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";

const CodBancoField = ({ onChange }) => {
  const [codbancosAll, setCodbancosAll] = useState([]);
  const [codbanco, setCodbanco] = useState([]);

  useEffect(() => {
    axios
      .get(process.env.REACT_APP_SERVICE_URL + "/counts/codbanco")
      .then((response) => {
        setCodbancosAll(response.data);
      })
      .catch((error) => {
        console.error("Erro pegando codbanco:", error);
      });
  }, []);

  const handleChange = (e, values) => {
    if (values) {
      const selectedCodbancos = codbancosAll.filter((codbanco) =>
        values.some((value) => value.value === codbanco.codigo)
      );
      setCodbanco(values); // Update selected values
      onChange(selectedCodbancos);
    }
  };

  return (
    <div className="state-field">
      <Autocomplete
        multiple
        id="codbanco"
        options={codbancosAll.map((codbanco) => ({
          label: codbanco.codigo + " - " + codbanco.descricao,
          value: codbanco.codigo,
        }))}
        value={codbanco}
        onChange={handleChange}
        getOptionLabel={(option) => option.label}
        renderInput={(params) => (
          <TextField {...params} label="Código" placeholder="Código" />
        )}
        sx={{ width: "100%" }}
        isOptionEqualToValue={(option, value) => option.value === value.value}
      />
    </div>
  );
};

export default CodBancoField;
