import React, {
  useEffect,
  useState,
  useImperativeHandle,
  forwardRef,
} from "react";
import axios from "axios";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { Box } from "@mui/material";

const SitFIeld = forwardRef(({ onChange, disabled, exclude }, ref) => {
  const [sitAll, setSitAll] = useState([]);
  const [sit, setSit] = useState([]);

  useEffect(() => {
    axios
      .get(process.env.REACT_APP_SERVICE_URL + "/counts/sit_pasep")
      .then((response) => {
        setSitAll(response.data);
      })
      .catch((error) => {
        console.error("Erro pegando situações:", error);
      });
  }, []);

  const clearState = () => {
    setSit(null);
  };

  useImperativeHandle(ref, () => ({
    clearState,
  }));

  const handleChange = (e, values) => {
    if (values) {
      const selectedSit = sitAll.filter((sit) =>
        values.some((value) => value.value === sit.situacao_vinculo)
      );
      setSit(values);
      onChange(selectedSit);
    }
  };

  return (
    <Box marginBottom="10px" className="state-field" width="100%">
      <Autocomplete
        disabled={disabled}
        multiple
        limitTags={2}
        id="combo-box-sit"
        options={sitAll.map((sit) => ({
          label: sit.situacao_vinculo,
          value: sit.situacao_vinculo,
        }))}
        value={sit}
        onChange={handleChange}
        getOptionLabel={(option) => option.label}
        renderInput={(params) => (
          <TextField {...params} label="Situação" placeholder="Situação" />
        )}
        isOptionEqualToValue={(option, value) => option.value === value.value}
        sx={{ width: "100%" }}
      />
    </Box>
  );
});

export default SitFIeld;
