// src/components/FormPage.js
import React, { useMemo, useState } from "react";
import { FormControl, Box } from "@mui/material";
import axios from "axios";
import ParamsGeo from "./ParamsGeo";
import ParamsDate from "./ParamsDate";
import EspecieField from "./EspecieField";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import CountFormWrapper from "./CountFormWrapper";
import CircularProgress, {
  circularProgressClasses,
} from "@mui/material/CircularProgress";

const FormIndef = () => {
  const [countParameters, setCountParameters] = useState({
    geo: "",
    date: "",
    especie: "",
  });

  const [isCounting, setIsCounting] = useState(false);

  const [countResult, setCountResult] = useState(null);

  const canSubmit = useMemo(() => {
    return !isCounting && countParameters.geo && countParameters.geo.length;
  }, [countParameters, isCounting]);

  const handleSubmit = (e) => {
    setIsCounting(true);
    e.preventDefault();

    axios
      .post(
        process.env.REACT_APP_SERVICE_URL + "/counts/retrieve_indef",
        countParameters
      )
      .then((response) => {
        setCountResult(response.data);
      })
      .catch((error) => {
        console.error("Error submitting form:", error);
      });
  };

  const handleGeoChange = (geo) => {
    setCountParameters({ ...countParameters, geo });
  };

  const handleDateChange = (date) => {
    setCountParameters({ ...countParameters, date });
  };

  const handleEspecieChange = (especie) => {
    setCountParameters({ ...countParameters, especie });
  };

  const countResultTableTitle = useMemo(() => {
    if (countResult && Array.isArray(countResult)) {
      return `INDEFERIDOS -
        ${
          countParameters.date.endDate
            ? `${countParameters.date.startDate.replace(
                /([0-9]{4})([0-9]{2})/,
                "$2/$1"
              )} à ${countParameters.date.endDate.replace(
                /([0-9]{4})([0-9]{2})/,
                "$2/$1"
              )}`
            : countParameters.date.startDate.replace(
                /([0-9]{4})([0-9]{2})/,
                "$2/$1"
              )
        }`;
    } else {
      return null;
    }
  }, [countResult, countParameters]);

  return (
    <CountFormWrapper
      title="INDEFERIDOS"
      countResultTableTitle={countResultTableTitle}
      countResult={countResult}
      onBack={() => {
        setCountResult(null);
        setIsCounting(false);
      }}
      form={
        <form onSubmit={handleSubmit}>
          <FormControl fullWidth>
            <ParamsGeo onChange={handleGeoChange} />
            <Box marginTop="35px" marginBottom="35px">
              <ParamsDate onChange={handleDateChange} />
            </Box>
            <Box marginTop="35px" marginBottom="35px">
              <Typography variant="h6" gutterBottom={true}>
                Espécie de Benefício
              </Typography>
              <EspecieField onChange={handleEspecieChange} />
            </Box>
            <Button variant="contained" type="submit" disabled={!canSubmit}>
              {isCounting ? (
                <>
                  Aguarde, em andamento.. &nbsp;
                  <CircularProgress
                    sx={{
                      color: "primary",
                      animationDuration: "2550ms",
                      [`& .${circularProgressClasses.circle}`]: {
                        strokeLinecap: "round",
                      },
                    }}
                    size={20}
                    thickness={4}
                  />
                </>
              ) : (
                "Gerar Contagem"
              )}
            </Button>
          </FormControl>
        </form>
      }
    ></CountFormWrapper>
  );
};

export default FormIndef;
