import React, { useEffect, useState } from "react";
import axios from "axios";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { Checkbox, createFilterOptions, ListItem } from "@mui/material";
import uniqBy from "lodash/uniqBy";
import match from "autosuggest-highlight/match";
import parse from "autosuggest-highlight/parse";

const CnaeField = ({ onChange }) => {
  let filteredCnaeOptions = [];

  const [cnaeAll, setCnaeAll] = useState([]);
  const [cnae, setCnae] = useState([]);
  const [cnaeSearchInputValue, setCnaeSearchInputValue] = useState("");

  useEffect(() => {
    axios
      .get(process.env.REACT_APP_SERVICE_URL + "/counts/cnae")
      .then((response) => {
        setCnaeAll(response.data);
      })
      .catch((error) => {
        console.error("Erro pegando cnae:", error);
      });
  }, []);

  const handleChange = (e, values, reason) => {
    let newCnae = [];
    if (reason === "clear") {
      setCnae([]);
      onChange([]);
      return;
    } else {
      if (values.find((option) => option.value === "all")) {
        setCnaeSearchInputValue("");
        newCnae = [...cnae, ...filteredCnaeOptions];
      } else {
        newCnae = [...values];
      }
    }
    setCnae(newCnae);
    onChange(newCnae);
    filteredCnaeOptions = [];
  };

  return (
    <div className="state-field">
      <Autocomplete
        multiple
        id="cnae"
        options={cnaeAll.map((cnae) => ({
          label: cnae.code + " - " + cnae.description,
          value: cnae.code,
        }))}
        value={cnae}
        disableCloseOnSelect
        onChange={handleChange}
        inputValue={cnaeSearchInputValue}
        onInputChange={(_, value, reason) =>
          reason !== "reset" && setCnaeSearchInputValue(value)
        }
        getOptionLabel={(option) => option.label}
        filterOptions={(options, params) => {
          const filter = createFilterOptions({
            matchFrom: "start",
            stringify: (option) => {
              return option.label;
            },
            limit: 100,
          });

          const filtered = uniqBy(filter(options, params), "value");
          filteredCnaeOptions = filtered;

          if (params.inputValue.length > 0) {
            return [
              { label: "SELECIONAR TODOS...", value: "all" },
              ...filtered,
            ];
          } else {
            return filtered;
          }
        }}
        renderInput={(params) => (
          <TextField {...params} label="CNAE" placeholder="CNAE" />
        )}
        sx={{ width: "100%" }}
        isOptionEqualToValue={(option, value) => option.value === value.value}
        renderOption={(props, option, { selected, inputValue }) => {
          const matches = match(option.label, inputValue);
          const parts = parse(option.label, matches);
          return (
            <ListItem
              {...props}
              sx={{
                backgroundColor:
                  option.value === "all" ? "#e6e5e4" : "transparent",
                "&:hover": {
                  backgroundColor: "#e6e5e4 !important",
                },
                fontSize: "0.85rem",
              }}
            >
              <Checkbox checked={selected} sx={{ ml: 2 * option.depth }} />
              <div>
                {parts.map((part, index) => (
                  <span
                    key={index}
                    style={{
                      fontWeight:
                        option.value === "all"
                          ? "700"
                          : part.highlight
                          ? 700
                          : 400,
                    }}
                  >
                    {part.text}
                  </span>
                ))}
              </div>
            </ListItem>
          );
        }}
      />
    </div>
  );
};

export default CnaeField;
