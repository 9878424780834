// src/components/FormPage.js
import React from "react";
import CssBaseline from "@mui/material/CssBaseline";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { Box } from "@mui/material";
import CountResultTable from "../CountResultTable";

const CountFormWrapper = ({
  title,
  countResultTableTitle,
  form,
  countResult,
  onBack,
}) => {
  return (
    <React.Fragment>
      <CssBaseline />
      <Box
        component="main"
        sx={{
          mt: { xs: 1, sm: 8 },
          padding: { xs: "25px", sm: "30px" },
          outline: { sm: "1px solid #ccc" },
          maxWidth: { xs: "100%", sm: "25%" },
        }}
        display="flex"
        flexDirection="row"
        alignItems="flex-start"
        justifyContent={countResult ? "flex-end" : "center"}
        flexWrap="nowrap"
        margin="0 auto"
        overflow="hidden"
      >
        <Paper
          sx={{
            margin: "0",
            outline: "transparent none",
            border: "transparent none",
            boxShadow: "none",
            width: "100%",
          }}
        >
          <Typography
            component="h1"
            variant="h4"
            align="center"
            gutterBottom={true}
          >
            {title}
          </Typography>
          {form}
        </Paper>
        {countResult && Array.isArray(countResult) && (
          <CountResultTable
            title={countResultTableTitle}
            result={countResult}
            onBack={onBack}
          />
        )}
      </Box>
    </React.Fragment>
  );
};

export default CountFormWrapper;
