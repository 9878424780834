import React, {
  useEffect,
  useState,
  useImperativeHandle,
  forwardRef,
} from "react";
import axios from "axios";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { Box } from "@mui/material";

const MunicipioField = forwardRef(({ onChange, codigo_uf, disabled }, ref) => {
  const [municipioAll, setMunicipioAll] = useState([]);
  const [municipio, setMunicipio] = useState([]);

  const fetchMunicipioAll = (codigo_uf) => {
    return axios
      .get(process.env.REACT_APP_SERVICE_URL + "/counts/municipios", {
        params: { codigo_uf },
      })
      .then((response) => {
        // console.log("Response received:", response.data); // Add this line
        setMunicipioAll(response.data);
        return response.data;
      })
      .catch((error) => {
        console.error("Erro pegando municipios:", error);
      });
  };

  useEffect(() => {
    if (codigo_uf) {
      fetchMunicipioAll(codigo_uf);
    }
  }, [codigo_uf]);

  const clearState = () => {
    setMunicipioAll([]);
    setMunicipio([]);
  };

  const setState = async (municipios, codigo_uf) => {
    const editMuncipiosIds = municipios.map((municipio) => municipio.id);

    if (codigo_uf) {
      const data = await fetchMunicipioAll(codigo_uf);
      const selectedMunicipios = data.filter((municipio) =>
        editMuncipiosIds.find((id) => id === municipio.codigo_ibge)
      );

      const options = selectedMunicipios.map((municipio) => ({
        label: municipio.nome,
        value: municipio.nome,
      }));

      setMunicipio(options);
      onChange(selectedMunicipios);

      return selectedMunicipios;
    }

    return []; // Return an empty array if codigo_uf is falsy
  };

  useImperativeHandle(ref, () => ({
    clearState,
    setState,
  }));

  const handleChange = (e, values) => {
    if (values) {
      const selectedMunicipios = municipioAll.filter((municipio) =>
        values.some((value) => value.value === municipio.nome)
      );
      setMunicipio(values); // Update selected values
      onChange(selectedMunicipios);
      // console.log(selectedMunicipios);
    }
  };

  return (
    <Box marginBottom="10px" className="city-field" width="100%">
      <Autocomplete
        disabled={disabled}
        multiple
        limitTags={2}
        id="multiple-municipios"
        options={municipioAll.map((municipio) => ({
          label: municipio.nome,
          value: municipio.nome,
        }))}
        value={municipio} // Use municipio as value prop
        onChange={handleChange}
        getOptionLabel={(option) => option.label}
        renderInput={(params) => (
          <TextField {...params} label="Municipios" placeholder="Municipios" />
        )}
        isOptionEqualToValue={(option, value) => option.value === value.value}
        sx={{ width: "100%" }}
      />
    </Box>
  );
});

export default MunicipioField;
