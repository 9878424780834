import { GoogleLogin, GoogleOAuthProvider } from "@react-oauth/google";
import { useAuth } from "../../hooks/AuthProvider";
import { Box } from "@mui/material";

const Login = () => {
  const auth = useAuth();

  return (
    <GoogleOAuthProvider clientId="41182751014-ml910f3cdt387gkrink0sofvbhs3g9ve.apps.googleusercontent.com">
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        width="100%"
        height="100vh"
      >
        <GoogleLogin
          onSuccess={(credentialResponse) => {
            auth.login(credentialResponse);
          }}
          onError={() => {
            console.log("Login Failed");
          }}
          auto_select
        />
      </Box>
    </GoogleOAuthProvider>
  );
};

export default Login;
