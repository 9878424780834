import * as React from "react";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
// import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import lupa from "./lupa.svg";
import filtro from "./filtro.svg";
import batepapo from "./bate-papo.svg";
import curvy from "./curvy-lines.png";

const item = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  px: 5,
};

const number = {
  fontSize: 24,
  fontFamily: "default",
  color: "secondary.main",
  fontWeight: "medium",
};

const image = {
  height: 55,
  my: 4,
};

function HowItWorks() {
  return (
    <Box
      component="section"
      sx={{ display: "flex", bgcolor: "#fff5f8", overflow: "hidden" }}
    >
      <Container
        sx={{
          mt: 10,
          mb: 15,
          position: "relative",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Box
          component="img"
          src={curvy}
          alt="curvy lines"
          sx={{
            pointerEvents: "none",
            position: "absolute",
            top: -180,
            opacity: 0.7,
          }}
        />
        <Typography
          variant="h4"
          fontWeight={700}
          sx={{
            mb: 14,
            letterSpacing: -2,
            fontSize: 48,
            fontFamily: "'Roboto Condensed', sans-serif",
            textTransform: "uppercase",
          }}
        >
          COMO FUNCIONA?
        </Typography>
        <div>
          <Grid container spacing={5}>
            <Grid item xs={12} md={4}>
              <Box sx={item}>
                <Box sx={number}>1.</Box>
                <Box component="img" src={filtro} alt="suitcase" sx={image} />
                <Typography
                  variant="h5"
                  align="center"
                  sx={{
                    fontFamily: "'Work Sans', sans-serif",
                    fontSize: 20,
                    fontWeight: 300,
                  }}
                >
                  Escolha os filtros que deseja aplicar.
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} md={4}>
              <Box sx={item}>
                <Box sx={number}>2.</Box>
                <Box component="img" src={lupa} alt="graph" sx={image} />
                <Typography
                  variant="h5"
                  align="center"
                  sx={{
                    fontSize: 20,
                    fontWeight: 300,
                    fontFamily: "'Work Sans', sans-serif",
                  }}
                >
                  Gere a contagem e visualize os resultados.
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} md={4}>
              <Box sx={item}>
                <Box sx={number}>3.</Box>
                <Box component="img" src={batepapo} alt="clock" sx={image} />
                <Typography
                  variant="h5"
                  align="center"
                  sx={{
                    fontSize: 20,
                    fontWeight: 300,
                    fontFamily: "'Work Sans', sans-serif",
                  }}
                >
                  Comunique ao cliente os resultados e negocie as especificações
                  finais.
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </div>
      </Container>
    </Box>
  );
}

export default HowItWorks;
