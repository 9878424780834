// src/components/FormPage.js
import React, { useMemo, useState } from "react";
import { FormControl, Box } from "@mui/material";
import axios from "axios";
import ParamsGeo from "./ParamsGeo";
import ParamsDateIniBen from "./ParamsDateIniBen";
import ParamsDateNasc from "./ParamsDateNasc.js";
import EspecieField from "./EspecieField";
import CodBancoField from "./CodBancoField/index.js";
import ConsignadoField from "./ConsignadoFIeld/index.js";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import CountFormWrapper from "./CountFormWrapper";
import CircularProgress, {
  circularProgressClasses,
} from "@mui/material/CircularProgress";

const FormBen = () => {
  const [countParameters, setCountParameters] = useState({
    geo: "",
    dateben: "",
    datenasc: "",
    especie: "",
    codbanco: "",
    consignado: "",
  });

  const [isCounting, setIsCounting] = useState(false);

  const [countResult, setCountResult] = useState(null);

  const canSubmit = useMemo(() => {
    return !isCounting && countParameters.geo && countParameters.geo.length;
  }, [countParameters, isCounting]);

  const handleSubmit = (e) => {
    setIsCounting(true);
    e.preventDefault();

    axios
      .post(
        process.env.REACT_APP_SERVICE_URL + "/counts/retrieve_beneficiarios",
        countParameters
      )
      .then((response) => {
        setCountResult(response.data);
      })
      .catch((error) => {
        console.error("Error submitting form:", error);
      });
  };

  const handleGeoChange = (geo) => {
    setCountParameters({ ...countParameters, geo });
  };

  const handleDateIniBenChange = (dateben) => {
    setCountParameters({ ...countParameters, dateben });
  };

  const handleDateNascChange = (datenasc) => {
    setCountParameters({ ...countParameters, datenasc });
  };

  const handleEspecieChange = (especie) => {
    setCountParameters({ ...countParameters, especie });
  };

  const handleCodBancoChange = (codbanco) => {
    setCountParameters({ ...countParameters, codbanco });
  };

  const handleConsignadoChange = (consignado) => {
    setCountParameters({ ...countParameters, consignado });
  };

  return (
    <CountFormWrapper
      title="BENEFICIÁRIOS INSS"
      countResultTableTitle="BENEFICIÁRIOS INSS"
      countResult={countResult}
      onBack={() => {
        setCountResult(null);
        setIsCounting(false);
      }}
      form={
        <form onSubmit={handleSubmit}>
          <FormControl fullWidth>
            <ParamsGeo onChange={handleGeoChange} />
            <Box marginTop="35px" marginBottom="35px">
              <Typography variant="h6" gutterBottom={true}>
                Espécie de Benefício
              </Typography>
              <EspecieField onChange={handleEspecieChange} />
            </Box>
            <Box marginTop="35px" marginBottom="35px">
              <ParamsDateIniBen onChange={handleDateIniBenChange} />
            </Box>
            <Box marginTop="35px" marginBottom="5px">
              <Typography variant="h6" gutterBottom={true}>
                Consignado
              </Typography>
              <ConsignadoField onChange={handleConsignadoChange} />
            </Box>
            {countParameters.consignado && (
              <Box marginTop="5px" marginBottom="35px">
                <Typography variant="h6" gutterBottom={true}>
                  Código do Banco
                </Typography>
                <CodBancoField onChange={handleCodBancoChange} />
              </Box>
            )}
            <Box marginTop="35px" marginBottom="35px">
              <ParamsDateNasc onChange={handleDateNascChange} />
            </Box>

            <Button variant="contained" type="submit" disabled={!canSubmit}>
              {isCounting ? (
                <>
                  Aguarde, em andamento.. &nbsp;
                  <CircularProgress
                    sx={{
                      color: "primary",
                      animationDuration: "2550ms",
                      [`& .${circularProgressClasses.circle}`]: {
                        strokeLinecap: "round",
                      },
                    }}
                    size={20}
                    thickness={4}
                  />
                </>
              ) : (
                "Gerar Contagem"
              )}
            </Button>
          </FormControl>
        </form>
      }
    ></CountFormWrapper>
  );
};

export default FormBen;
