import React from "react";
import HowItWorks from "./Views/HowItWorks";
import Counts from "./Views/Counts";

const HomePage = () => {
  return (
    <div>
      <Counts />
      <HowItWorks />
    </div>
  );
};

export default HomePage;
