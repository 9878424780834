import React, { useState, useRef, useMemo } from "react";
import UfField from "./UfField";
import MunicipioField from "./MunicipioField";
import { Button, IconButton, Typography } from "@mui/material";
import { ToggleButton, ToggleButtonGroup, Box } from "@mui/material";
import shortid from "shortid";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import Clamp from "react-multiline-clamp";

const ParamsGeo = ({ onChange }) => {
  const [countParameters, setCountParameters] = useState({
    uf: "",
    municipio: [],
  });

  const [geoAll, setGeoAll] = useState(false);

  const [geoList, setGeoList] = useState([]);
  const ufFieldRef = useRef();
  const municipioFieldRef = useRef();

  const handleUfChange = (uf) => {
    setCountParameters({ ...countParameters, uf });
  };

  const handleMunicipioChange = (municipio) => {
    setCountParameters({ ...countParameters, municipio });
  };

  const handleToggleGeoAll = (e, value) => {
    setGeoAll(value === "sim" ? true : false);
  };

  const canSelectGeo = useMemo(() => {
    return !geoAll && !geoList.find((geo) => geo.uf === "ALL");
  }, [geoList, geoAll]);

  const canAddGeo = useMemo(() => {
    return (
      (geoAll || countParameters.uf) && !geoList.find((geo) => geo.uf === "ALL")
    );
  }, [geoList, geoAll, countParameters]);

  const canAddGeoAll = useMemo(() => {
    return geoList.length === 0;
  }, [geoList]);

  const selectedUFs = useMemo(() => {
    return geoList.map((geo) => geo.uf?.id);
  }, [geoList]);

  const handleAddGeo = () => {
    let newGeo = null;

    if (geoAll) {
      newGeo = {
        id: shortid.generate(), // Generate a unique ID using shortid
        uf: "ALL",
        municipio: "",
      };
    } else {
      let tempUf = [];
      let tempMunicipio = [];
      if (countParameters.uf && countParameters.municipio.length > 0) {
        const newUF = {
          name: countParameters.uf.uf,
          id: countParameters.uf.codigo_uf,
        };
        tempUf = newUF;

        const newMunicipio = countParameters.municipio.map((municipio) => ({
          name: municipio.nome,
          id: municipio.codigo_ibge,
        }));
        tempMunicipio = newMunicipio;
      }

      if (countParameters.uf && countParameters.municipio.length === 0) {
        const newUF = {
          name: countParameters.uf.uf,
          id: countParameters.uf.codigo_uf,
        };
        const newMunicipio = "";
        tempUf = newUF;
        tempMunicipio = newMunicipio;
      }

      newGeo = {
        id: shortid.generate(), // Generate a unique ID using shortid
        uf: tempUf,
        municipio: tempMunicipio,
      };
    }

    const newGeoList = [...geoList, newGeo];

    setGeoList(newGeoList);
    onChange(newGeoList);
    setCountParameters({ uf: "", municipio: "" }); // Reset uf e municipio
    municipioFieldRef.current.clearState();
    ufFieldRef.current.clearState();
    setGeoAll(false);
  };

  const removeFilter = (id) => {
    const updatedList = geoList.filter((param) => param.id !== id);
    setGeoList(updatedList);
    onChange(updatedList);
  };

  const handleEditGeo = (id) => async () => {
    const geo = geoList.find((param) => param.id === id);

    let editUF = "";
    let editMunicipio = "";
    let editCountParameters = { ...countParameters }; // Create a new object

    if (geo.uf !== "ALL") {
      editUF = await ufFieldRef.current.setState(geo.uf);
    }

    if (geo.municipio.length > 0) {
      editMunicipio = await municipioFieldRef.current.setState(
        geo.municipio,
        geo.uf.id
      );
    }

    editCountParameters = {
      uf: editUF,
      municipio: editMunicipio,
    };
    setCountParameters(editCountParameters);

    const newGeoList = geoList.filter((param) => param.id !== id);
    setGeoList(newGeoList);
    onChange(newGeoList, editCountParameters); // Use the editCountParameters here

    if (geo.uf === "ALL") {
      setGeoAll(true);
    }
  };

  return (
    <>
      <Typography
        variant="h6"
        gutterBottom={true}
        display="flex"
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
        marginTop={3}
      >
        Região
        <ToggleButtonGroup
          color="primary"
          value={geoAll ? "sim" : null}
          size="small"
          exclusive
          onChange={handleToggleGeoAll}
          aria-label="Incluir filiais"
          disabled={!canAddGeoAll}
        >
          <ToggleButton
            value="sim"
            sx={{
              "&.Mui-selected": {
                backgroundColor: "#ED2D6B",
                color: "white",
              },
            }}
          >
            {geoAll ? (
              <CheckBoxIcon sx={{ marginRight: "5px" }} />
            ) : (
              <CheckBoxOutlineBlankIcon sx={{ marginRight: "5px" }} />
            )}{" "}
            TODAS AS REGIÕES
          </ToggleButton>
        </ToggleButtonGroup>
      </Typography>
      <Box>
        <Box
          display="flex"
          flexDirection="column"
          alignItems="flex-end"
          justifyContent="space-between"
        >
          <UfField
            onChange={handleUfChange}
            ref={ufFieldRef}
            disabled={!canSelectGeo}
            exclude={selectedUFs}
          />
          <MunicipioField
            codigo_uf={countParameters.uf.codigo_uf}
            ref={municipioFieldRef}
            onChange={handleMunicipioChange}
            disabled={!canSelectGeo}
          />
          <Button
            variant="outlined"
            onClick={handleAddGeo}
            size="small"
            disabled={!canAddGeo}
          >
            ADICIONAR {geoAll ? "TODAS" : "REGIÃO"}
          </Button>
        </Box>
        <Box marginTop="20px">
          {/* <Typography color='grafite' marginBottom='8px'>regiões selecionadas</Typography> */}
          {geoList.map((param, index) => (
            <Box
              key={param.id}
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                border: 1,
                borderRadius: "7px",
                borderWidth: "1px",
                borderColor: "rgba(0, 0, 0, 0.12)",
                backgroundColor: "#eee",
                padding: "10px",
                margin: "0 0 8px 0",
              }}
            >
              <IconButton
                variant="outlined"
                onClick={() => removeFilter(param.id)}
                size="small"
              >
                <DeleteIcon />
              </IconButton>
              <Box fontSize="14px" sx={{ flex: 1, paddingLeft: "10px" }}>
                <span>
                  <strong>UF:</strong> {param.uf.name || "TODOS"}
                </span>
                <br />
                <span>
                  <strong>Municipios:</strong>{" "}
                  <Clamp
                    lines={3}
                    withToggle
                    showMoreElement={({ toggle }) => (
                      <Typography
                        type="button"
                        onClick={toggle}
                        sx={{
                          fontSize: "14px",
                          color: "#ED2D6B",
                          fontStyle: "italic",
                          marginTop: "8px",
                        }}
                      >
                        [expandir]
                      </Typography>
                    )}
                    showLessElement={({ toggle }) => (
                      <Typography
                        type="button"
                        onClick={toggle}
                        sx={{
                          fontSize: "14px",
                          color: "#ED2D6B",
                          fontStyle: "italic",
                          marginTop: "8px",
                        }}
                      >
                        [recolher]
                      </Typography>
                    )}
                  >
                    {param.municipio.length > 0 ? (
                      param.municipio.map((municipio, index, entries) => (
                        <span key={municipio.id}>
                          {municipio.name}
                          {index < entries.length - 1 ? ", " : ""}
                        </span>
                      ))
                    ) : (
                      <span>TODOS</span>
                    )}
                  </Clamp>
                </span>
              </Box>
              <IconButton
                variant="outlined"
                onClick={handleEditGeo(param.id)}
                size="small"
              >
                <EditIcon />
              </IconButton>
            </Box>
          ))}
        </Box>
      </Box>
    </>
  );
};

export default ParamsGeo;
