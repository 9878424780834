import React, { useState } from "react";
import { DatePicker } from "@mui/x-date-pickers";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import { Typography } from "@mui/material";

const ParamsDate = ({ onChange }) => {
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [isDateRange, setIsDateRange] = useState(false);

  const handleTogglePicker = () => {
    setIsDateRange((prev) => !prev);
    setEndDate("");
  };

  const prepareDate = (dates) => {
    if (dates[1]) {
      const startMonth = (dates[0].getMonth() + 1).toString().padStart(2, "0");
      const startYear = dates[0].getFullYear();
      const startDate = `${startYear}${startMonth}`;

      const endMonth = (dates[1].getMonth() + 1).toString().padStart(2, "0");
      const endYear = dates[1].getFullYear();
      const endDate = `${endYear}${endMonth}`;

      return { startDate, endDate };
    } else if (dates[0]) {
      const startMonth = (dates[0].getMonth() + 1).toString().padStart(2, "0");
      const startYear = dates[0].getFullYear();
      const startDate = `${startYear}${startMonth}`;
      return { startDate, endDate: "" };
    } else {
      return { startDate: "", endDate: "" };
    }
  };
  const handleStartDateChange = (date) => {
    setStartDate(date);
    onChange(prepareDate([date, endDate]));
  };

  const handleEndDateChange = (date) => {
    setEndDate(date);
    onChange(prepareDate([startDate, date]));
  };

  return (
    <div>
      <Typography
        variant="h6"
        gutterBottom={true}
        display="flex"
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
      >
        Mês/Ano Competência{" "}
        <Button
          size="small"
          variant="outlined"
          onClick={handleTogglePicker}
          sx={{ marginBottom: 1, marginTop: 1 }}
        >
          {isDateRange ? "PADRÃO" : "INTERVALO"}
        </Button>
      </Typography>

      <Box sx={{ display: "flex", alignItems: "center", marginTop: "1rem" }}>
        {isDateRange ? (
          <>
            <DatePicker
              date={startDate}
              inputFormat="yyyy-MM"
              views={["year", "month"]}
              label="Inicio"
              format="MM/yyyy"
              minDate={new Date("2012-03-01")}
              maxDate={new Date("2025-01-01")}
              onChange={handleStartDateChange}
              sx={{ marginRight: 1 }}
              slotProps={{ popper: { placement: "top-end" } }}
            />
            <DatePicker
              date={endDate}
              inputFormat="yyyy-MM"
              views={["year", "month"]}
              label="Fim"
              format="MM/yyyy"
              minDate={new Date("2012-03-01")}
              maxDate={new Date("2025-01-01")}
              onChange={handleEndDateChange}
              slotProps={{ popper: { placement: "top-end" } }}
            />
          </>
        ) : (
          <DatePicker
            date={startDate}
            inputFormat="yyyy-MM"
            views={["year", "month"]}
            label="Mes/Ano"
            format="MM/yyyy"
            minDate={new Date("2012-03-01")}
            maxDate={new Date("2025-01-01")}
            onChange={handleStartDateChange}
            slotProps={{ popper: { placement: "top-end" } }}
          />
        )}
      </Box>
    </div>
  );
};

export default ParamsDate;
