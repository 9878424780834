import React, { useEffect, useState } from "react";
import axios from "axios";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { Checkbox, createFilterOptions, ListItem } from "@mui/material";
import uniqBy from "lodash/uniqBy";
import match from "autosuggest-highlight/match";
import parse from "autosuggest-highlight/parse";

const EspecieField = ({ onChange }) => {
  let filteredEspeciesOptions = [];

  const [especiesAll, setEspeciesAll] = useState([]);
  const [especie, setEspecie] = useState([]);
  const [especieSearchInputValue, setEspecieSearchInputValue] = useState("");

  useEffect(() => {
    axios
      .get(process.env.REACT_APP_SERVICE_URL + "/counts/especies")
      .then((response) => {
        setEspeciesAll(response.data);
      })
      .catch((error) => {
        console.error("Erro pegando especies:", error);
      });
  }, []);

  const handleChange = (e, values, reason) => {
    let newEspecie = [];
    if (reason === "clear") {
      setEspecie([]);
      onChange([]);
      return;
    } else {
      if (values.find((option) => option.value === "all")) {
        setEspecieSearchInputValue("");
        newEspecie = [...especie, ...filteredEspeciesOptions];
      } else {
        newEspecie = [...values];
      }
    }
    setEspecie(newEspecie);
    onChange(newEspecie);
    filteredEspeciesOptions = [];
  };

  return (
    <div className="state-field">
      <Autocomplete
        multiple
        id="especie"
        options={especiesAll.map((beneficio) => ({
          label: beneficio.descricao + " (" + beneficio.especie + ")",
          value: beneficio.especie,
        }))}
        value={especie}
        disableCloseOnSelect
        onChange={handleChange}
        inputValue={especieSearchInputValue}
        onInputChange={(_, value, reason) =>
          reason !== "reset" && setEspecieSearchInputValue(value)
        }
        getOptionLabel={(option) => option.label}
        filterOptions={(options, params) => {
          const filter = createFilterOptions({
            stringify: (option) => {
              return option.label;
            },
            limit: 100,
          });

          const filtered = uniqBy(filter(options, params), "value");
          filteredEspeciesOptions = filtered;

          if (params.inputValue.length > 0) {
            return [
              { label: "SELECIONAR TODOS...", value: "all" },
              ...filtered,
            ];
          } else {
            return filtered;
          }
        }}
        renderInput={(params) => (
          <TextField {...params} label="Espécie" placeholder="Espécie" />
        )}
        sx={{ width: "100%" }}
        isOptionEqualToValue={(option, value) => option.value === value.value}
        renderOption={(props, option, { selected, inputValue }) => {
          const matches = match(option.label, inputValue);
          const parts = parse(option.label, matches);
          return (
            <ListItem
              {...props}
              sx={{
                backgroundColor:
                  option.value === "all" ? "#e6e5e4" : "transparent",
                "&:hover": {
                  backgroundColor: "#e6e5e4 !important",
                },
                fontSize: "0.85rem",
              }}
            >
              <Checkbox checked={selected} sx={{ ml: 2 * option.depth }} />
              <div>
                {parts.map((part, index) => (
                  <span
                    key={index}
                    style={{
                      fontWeight:
                        option.value === "all"
                          ? "700"
                          : part.highlight
                          ? 700
                          : 400,
                    }}
                  >
                    {part.text}
                  </span>
                ))}
              </div>
            </ListItem>
          );
        }}
      />
    </div>
  );
};

export default EspecieField;
