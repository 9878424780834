import React, { useMemo, useState } from "react";
import TextField from "@mui/material/TextField";

import { cnpj } from "cpf-cnpj-validator";

const CnpjField = ({ onChange }) => {
  const [cnpjs, setCnpjs] = useState([]);

  const validEntries = useMemo(() => {
    if (cnpjs && cnpjs.length > 0) {
      return cnpjs
        .split("\n")
        .filter((doc) => cnpj.isValid(doc))
        .join("\n");
    }
    return "";
  }, [cnpjs]);

  const handleChange = (values) => {
    onChange(validEntries);
    setCnpjs(values);
  };

  const formatCnpjs = (values) => {
    let splitted = values.split("\n");
    splitted = splitted
      .map((doc) => {
        let clean = doc.replace(/[^0-9]/g, "");
        if (clean.length >= 14) {
          if (cnpj.isValid(clean)) {
            return cnpj.format(clean);
          } else {
            return null;
          }
        } else {
          return doc;
        }
      })
      .filter((doc, index, self) => self.indexOf(doc) === index)
      .filter((doc) => doc !== null)
      .join("\n");
    
    return splitted;
  };

  return (
    <div className="state-field">
      <div>
        <TextField
          variant="outlined"
          label={`Lista de CNPJs${
            validEntries && validEntries.length > 0
              ? ` (${validEntries.split("\n").length} válidos)`
              : ""
          }`}
          name="cnpjs"
          required={false}
          helperText={`Adicione neste campo uma lista de CNPJs válidos, um por linha`}
          multiline
          rows={10}
          fullWidth
          spellCheck={false}
          value={cnpjs}
          onChange={(e) => {
            e.preventDefault();
            handleChange(formatCnpjs(e.target.value))
          }}
          onPasteCapture={(e) => {
            e.preventDefault();
            handleChange(formatCnpjs(e.clipboardData.getData("text")));
          }}
          InputLabelProps={{
            sx: {
              fontSize: "1rem !important",
            },
          }}
          InputProps={{
            sx: {
              "& legend": {
                fontSize: "13px",
              },
            },
          }}
          FormHelperTextProps={{
            sx: {
              fontSize: "12px",
              marginLeft: 0,
              marginTop: "5px",
            },
          }}
        />
      </div>
    </div>
  );
};

export default CnpjField;
