// src/components/FormPage.js
import React, { useMemo, useState } from "react";
import {
  FormControl,
  ToggleButton,
  ToggleButtonGroup,
  Box,
} from "@mui/material";

import axios from "axios";
import ParamsGeo from "./ParamsGeo";
import ParamsDate from "./ParamsDate";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import CboField from "./CboFIeld";
import CnaeField from "./CnaeField";
import CnpjField from "./CnpjField";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CountFormWrapper from "./CountFormWrapper";
import CircularProgress, {
  circularProgressClasses,
} from "@mui/material/CircularProgress";

const FormDem = () => {
  const [countParameters, setCountParameters] = useState({
    geo: "",
    date: "",
    cnaes: "",
    cbos: "",
    cnpjs: "",
    incluir_filiais: true,
  });

  const [isCounting, setIsCounting] = useState(false);

  const [countResult, setCountResult] = useState(null);

  const canSubmit = useMemo(() => {
    return !isCounting && countParameters.geo && countParameters.geo.length;
  }, [countParameters, isCounting]);

  const handleSubmit = (e) => {
    setIsCounting(true);
    e.preventDefault();

    axios
      .post(
        process.env.REACT_APP_SERVICE_URL + "/counts/retrieve_demitidos",
        countParameters
      )
      .then((response) => {
        setCountResult(response.data);
        console.log("Deu Certo:", countParameters);
        console.log("Resposta:", response.data);
      })
      .catch((error) => {
        console.error("Error submitting form:", error);
      });
  };

  const handleGeoChange = (geo) => {
    setCountParameters({ ...countParameters, geo });
  };

  const handleDateChange = (date) => {
    setCountParameters({ ...countParameters, date });
  };

  const handleCnaeChange = (cnaes) => {
    setCountParameters({ ...countParameters, cnaes });
  };

  const handleCboChange = (cbos) => {
    setCountParameters({ ...countParameters, cbos });
  };

  const handleCnpjChange = (cnpjs) => {
    setCountParameters({ ...countParameters, cnpjs });
  };

  const handleIncluirFiliaisChange = (e, incluir_filiais) => {
    setCountParameters({
      ...countParameters,
      incluir_filiais: incluir_filiais === "sim" ? true : false,
    });
  };

  const countResultTableTitle = useMemo(() => {
    if (countResult && Array.isArray(countResult)) {
      return `DEMITIDOS -
        ${
          countParameters.date.endDate
            ? `${countParameters.date.startDate.replace(
                /([0-9]{4})([0-9]{2})/,
                "$2/$1"
              )} à ${countParameters.date.endDate.replace(
                /([0-9]{4})([0-9]{2})/,
                "$2/$1"
              )}`
            : countParameters.date.startDate.replace(
                /([0-9]{4})([0-9]{2})/,
                "$2/$1"
              )
        }`;
    } else {
      return null;
    }
  }, [countResult, countParameters]);

  return (
    <CountFormWrapper
      title="DEMITIDOS"
      countResultTableTitle={countResultTableTitle}
      countResult={countResult}
      onBack={() => {
        setCountResult(null);
        setIsCounting(false);
      }}
      form={
        <form onSubmit={handleSubmit}>
          <FormControl fullWidth>
            <ParamsGeo onChange={handleGeoChange} />
            <Box marginTop="35px">
              <ParamsDate onChange={handleDateChange} />
            </Box>
            <Box marginTop="35px">
              <Typography variant="h6" gutterBottom={true}>
                CNAEs
              </Typography>
              <CnaeField onChange={handleCnaeChange} />
            </Box>
            <Box marginTop="35px">
              <Typography variant="h6" gutterBottom={true}>
                CBOs
              </Typography>
              <CboField onChange={handleCboChange} />
            </Box>
            <Box marginTop="35px" marginBottom="35px">
              <Typography
                variant="h6"
                gutterBottom={true}
                display="flex"
                flexDirection="row"
                alignItems="center"
                justifyContent="space-between"
              >
                CNPJs{" "}
                <ToggleButtonGroup
                  color="primary"
                  value={countParameters.incluir_filiais ? "sim" : null}
                  size="small"
                  exclusive
                  onChange={handleIncluirFiliaisChange}
                  aria-label="Incluir filiais"
                >
                  <ToggleButton
                    defaultChecked
                    value="sim"
                    sx={{
                      "&.Mui-selected": {
                        backgroundColor: "#ED2D6B",
                        color: "white",
                      },
                    }}
                  >
                    {countParameters.incluir_filiais ? (
                      <CheckBoxIcon sx={{ marginRight: "5px" }} />
                    ) : (
                      <CheckBoxOutlineBlankIcon sx={{ marginRight: "5px" }} />
                    )}{" "}
                    Incluir filiais
                  </ToggleButton>
                </ToggleButtonGroup>
              </Typography>
              <CnpjField onChange={handleCnpjChange} />
            </Box>
            <Button variant="contained" type="submit" disabled={!canSubmit}>
              {isCounting ? (
                <>
                  Aguarde, em andamento.. &nbsp;
                  <CircularProgress
                    sx={{
                      color: "primary",
                      animationDuration: "2550ms",
                      [`& .${circularProgressClasses.circle}`]: {
                        strokeLinecap: "round",
                      },
                    }}
                    size={20}
                    thickness={4}
                  />
                </>
              ) : (
                "Gerar Contagem"
              )}
            </Button>
          </FormControl>
        </form>
      }
    ></CountFormWrapper>
  );
};

export default FormDem;
