// src/components/FormPage.js
import React, { useMemo, useState } from "react";
import { Box, FormControl } from "@mui/material";

import axios from "axios";
import ParamsGeo from "./ParamsGeo";
import Button from "@mui/material/Button";
import CountFormWrapper from "./CountFormWrapper";
import RadioGroup from "@mui/material/RadioGroup";
import Radio from "@mui/material/Radio";
import FormControlLabel from "@mui/material/FormControlLabel";
import Typography from "@mui/material/Typography";
import CircularProgress, {
  circularProgressClasses,
} from "@mui/material/CircularProgress";

const FormBpc = () => {
  const [countParameters, setCountParameters] = useState({
    geo: "",
    idade: "65",
  });

  const [isCounting, setIsCounting] = useState(false);

  const [countResult, setCountResult] = useState(null);

  const canSubmit = useMemo(() => {
    return !isCounting && countParameters.geo && countParameters.geo.length;
  }, [countParameters, isCounting]);

  const handleSubmit = (e) => {
    setIsCounting(true);
    e.preventDefault();

    axios
      .post(
        process.env.REACT_APP_SERVICE_URL + "/counts/retrieve_bpc",
        countParameters
      )
      .then((response) => {
        setCountResult(response.data);
      })
      .catch((error) => {
        console.error("Error submitting form:", error);
      });
  };

  const handleGeoChange = (geo) => {
    setCountParameters({ ...countParameters, geo });
  };

  const handleIdadeChange = (e) => {
    setCountParameters({ ...countParameters, idade: e.target.value });
    console.log(countParameters);
  };

  return (
    <CountFormWrapper
      title="TENTATIVA BPC"
      countResultTableTitle="TENTATIVA BPC"
      countResult={countResult}
      onBack={() => {
        setCountResult(null);
        setIsCounting(false);
      }}
      form={
        <form onSubmit={handleSubmit}>
          <FormControl fullWidth>
            <ParamsGeo onChange={handleGeoChange} />
            <Typography
              variant="h6"
              gutterBottom={true}
              display="flex"
              flexDirection="row"
              alignItems="center"
              justifyContent="space-between"
              marginTop="35px"
            >
              Idade{" "}
            </Typography>
            <Box marginTop="10px" marginBottom="35px">
              <RadioGroup
                aria-labelledby="demo-row-radio-buttons-group-label"
                defaultValue="65"
                name="row-radio-buttons-group"
                onChange={handleIdadeChange}
              >
                <FormControlLabel
                  value="65"
                  control={<Radio />}
                  label="+ 65 anos"
                  defaultChecked={true}
                />
                <FormControlLabel
                  value="64.5"
                  control={<Radio />}
                  label="+ 64 anos e 6 meses"
                />
                <FormControlLabel
                  value="64"
                  control={<Radio />}
                  label="+ 64 anos"
                />
              </RadioGroup>
            </Box>
            <Button variant="contained" type="submit" disabled={!canSubmit}>
              {isCounting ? (
                <>
                  Aguarde, em andamento.. &nbsp;
                  <CircularProgress
                    sx={{
                      color: "primary",
                      animationDuration: "2550ms",
                      [`& .${circularProgressClasses.circle}`]: {
                        strokeLinecap: "round",
                      },
                    }}
                    size={20}
                    thickness={4}
                  />
                </>
              ) : (
                "Gerar Contagem"
              )}
            </Button>
          </FormControl>
        </form>
      }
    ></CountFormWrapper>
  );
};

export default FormBpc;
